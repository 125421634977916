import { AmplitudeContext } from "@hygo/shared/amplitude";
import { UserContext } from "@hygo/shared/contexts";
import { useRPG } from "@hygo/shared/feature-fields-manager";
import { Field, FieldsManagerEvents, RPGField } from "@hygo/shared/models";
import { COLORS, convertToHa, hexToRGBA, HORIZONTAL_PADDING } from "@hygo/shared/utils";
import { CropsScreenContext } from "@hygo/web/contexts";
import "mapbox-gl/dist/mapbox-gl.css";
import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css";
import { CreationMode, DashboardMode } from "@hygo/web/models";
import { FixMapboxMap } from "@hygo/web/ui-components";
import { fitMapBounds } from "@hygo/web/utils";
import MapboxDraw, { DrawCustomMode, DrawUpdateEvent, MapMouseEvent } from "@mapbox/mapbox-gl-draw";
import * as turf from "@turf/turf";
import { GeoJSON } from "geojson";
import { Feature, Polygon } from "geojson";
import _ from "lodash";
import { GeoJSONFeature, MapEvent } from "mapbox-gl";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import Map, { Layer, Marker, Source } from "react-map-gl";
import styled from "styled-components";

import { DrawControl } from "./DrawControl";

const MarkerWrapper = styled.div`
	background-color: var(--night-100);
	border-radius: 4px;
	padding: 8px;
	width: fit-content;
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 4px;
	white-space: nowrap;
	color: var(--white-100);
`;

const MarkerTitle = styled.h5``;

interface MapViewProps {
	fieldDescriptionCardWidth: number;
}

const MapView = ({ fieldDescriptionCardWidth }: MapViewProps): JSX.Element => {
	const { formatFields, logAnalyticEvent } = useContext(AmplitudeContext);
	const { activeFields, crops, farmerSelected, user } = useContext(UserContext);
	const {
		createdPolygonDetails,
		creationMode,
		currentMode,
		drawRef,
		editedFieldId,
		fieldCoordinates,
		handleFieldSelection,
		mapRef,
		RPGFields,
		selectedFields,
		setCreatedPolygonDetails,
		setRPGFields,
		updateFieldCoordinates
	} = useContext(CropsScreenContext);
	const { t } = useTranslation();
	const showRpg =
		!(fieldCoordinates?.length > 0) &&
		(currentMode === DashboardMode.NEW_FIELD || currentMode === DashboardMode.UPDATE_FIELD) &&
		creationMode === CreationMode.RPG;
	const interactiveLayerIds = [currentMode === DashboardMode.FIELD_LIST && "inside", showRpg && "rpgInside"]?.filter(
		(n) => n
	);
	const { area, center, loadRPGFields, selectRPGField } = useRPG({ fieldCoordinates, RPGFields });

	const onLoad = (): void => {
		fitMapBounds({ fields: activeFields, mapRef, userLocation: user?.location });
	};

	const onClickField = (features: Array<GeoJSONFeature>): void => {
		let clickedField = features
			.filter(
				(v, i, a) =>
					v.geometry.type === "Polygon" &&
					a.findIndex((v2) => v2.properties?.field?.id === v.properties?.field?.id) === i
			)
			?.map((f) => f.properties.field)?.[0];

		if (!clickedField) return;
		clickedField = JSON.parse(clickedField) as Field;
		logAnalyticEvent(FieldsManagerEvents.selectFieldFromMap, {
			field: formatFields([clickedField], crops),
			type: selectedFields?.find((field) => field.id === clickedField.id) ? "Retrait" : "Ajout"
		});
		handleFieldSelection({
			centerMap: true,
			field: clickedField,
			overrideActiveFields: null,
			overrideNewSelectedFields: null,
			selection: false
		});
	};

	const onClickRPGField = (features: Array<GeoJSONFeature>): void => {
		const clickedFeature = features.filter(
			(v, i, a) =>
				v.geometry.type === "Polygon" &&
				a.findIndex((v2) => v2.properties?.field?.id === v.properties?.field?.id) === i
		)[0];

		if (!clickedFeature) return;
		const clickedField = JSON.parse(clickedFeature?.properties?.field) as RPGField;
		const fieldAlreadyUsed = JSON.parse(clickedFeature?.properties?.fieldAlreadyUsed) as boolean;

		selectRPGField(fieldAlreadyUsed, () => {
			updateFieldCoordinates(clickedField?.coordinates, true);
			drawRef.current.changeMode("direct_select" as never, { featureId: "polygon" });
			fitMapBounds({ fields: [clickedField], mapRef, userLocation: user?.location });
		});
	};

	const onClick = (e: MapMouseEvent): void => {
		if (interactiveLayerIds.includes("inside")) onClickField(e?.features);
		else if (interactiveLayerIds.includes("rpgInside")) onClickRPGField(e?.features);
		else if (drawRef.current.getMode() === "draw_polygon") {
			const featureIds = drawRef.current.getFeatureIdsAt(e.point);
			const feature = drawRef.current.get(featureIds[0]) as Feature<Polygon>;
			if (!feature) return;
			const coordinates = feature?.geometry?.coordinates[0];
			const polygon = coordinates?.length >= 4 && turf.polygon([coordinates]);
			const createdPolygonArea = polygon && convertToHa(turf.area(polygon));
			const createdPolygonCenter = polygon && turf.centerOfMass(polygon)?.geometry?.coordinates;
			setCreatedPolygonDetails({ area: createdPolygonArea, center: createdPolygonCenter });
		}
	};

	const fetchRPGFields = async (e: MapEvent): Promise<void> => {
		const fetchedRPG = await loadRPGFields({
			latitude: e.target.getCenter().lat,
			longitude: e.target.getCenter().lng
		});
		setRPGFields(fetchedRPG);
	};

	const onUpdate = (e: { features: Feature<Polygon>[] } & DrawUpdateEvent): void => {
		updateFieldCoordinates(
			e?.features[0]?.geometry?.coordinates[0]?.map((c) => ({ lat: c[1], lon: c[0] })),
			false
		);
	};

	const displayedCenter = center || createdPolygonDetails?.center;
	const displayedArea = area || createdPolygonDetails?.area;

	return (
		<Map
			attributionControl={false}
			interactiveLayerIds={interactiveLayerIds}
			logoPosition="bottom-right"
			mapStyle="mapbox://styles/mapbox/satellite-streets-v12"
			onClick={onClick}
			onIdle={(e) => farmerSelected && fetchRPGFields(e)}
			onLoad={onLoad}
			padding={{
				bottom: HORIZONTAL_PADDING,
				left:
					HORIZONTAL_PADDING +
					(currentMode === DashboardMode.FIELD_LIST && selectedFields?.length === 1
						? fieldDescriptionCardWidth
						: 0),

				right: HORIZONTAL_PADDING,
				top: HORIZONTAL_PADDING
			}}
			ref={mapRef}
		>
			<FixMapboxMap mapRef={mapRef}>
				<>
					<Source
						data={{
							features:
								showRpg &&
								RPGFields?.flatMap((field) => {
									const fieldAlreadyUsed = activeFields.some((c) =>
										_.isEqual(c.coordinates, field.coordinates)
									);

									const polygon = turf.polygon([field?.coordinates?.map((c) => [c.lon, c.lat])]);
									const line = turf.lineString(field?.coordinates?.map((c) => [c.lon, c.lat]));
									return [
										{
											...polygon,
											id: "polygon",
											properties: {
												field,
												fieldAlreadyUsed,
												fillColor: COLORS.WHITE[25],
												fillColorUsed: COLORS.TANGERINE[25]
											},
											type: "Feature"
										},
										{
											...line,
											id: "line",
											properties: {
												fieldAlreadyUsed,
												lineColor: COLORS.WHITE[100],
												lineColorUsed: COLORS.TANGERINE[100]
											},
											type: "Feature"
										}
									];
								}),
							type: "FeatureCollection"
						}}
						id="rpgPolygons"
						type="geojson"
					>
						<Layer
							filter={["==", "$type", "Polygon"]}
							id="rpgInside"
							paint={{
								"fill-color": [
									"case",
									["get", "fieldAlreadyUsed"],
									["get", "fillColorUsed"],
									["get", "fillColor"]
								]
							}}
							source="rpgPolygons"
							type="fill"
						/>
						<Layer
							filter={["==", "$type", "LineString"]}
							id="rpgBorder"
							paint={{
								"line-color": [
									"case",
									["get", "fieldAlreadyUsed"],
									["get", "lineColorUsed"],
									["get", "lineColor"]
								],
								"line-offset": 2.5,
								"line-width": 5
							}}
							source="rpgPolygons"
							type="line"
						/>
					</Source>
					<Source
						data={{
							features:
								!showRpg &&
								activeFields
									?.filter((f) => f.id !== editedFieldId && f?.coordinates)
									?.flatMap((field, i) => {
										const polygon = turf.polygon([field.coordinates.map((c) => [c.lon, c.lat])]);
										const isSelected = Boolean(selectedFields?.find((sf) => sf.id === field.id));
										const crop = crops?.find((c) => c.id === field?.cropId);

										const line = turf.lineString(field.coordinates.map((c) => [c.lon, c.lat]));
										return [
											{
												...polygon,
												id: "polygon",
												properties: {
													field,
													fillColor: hexToRGBA(crop?.color, 0.5, COLORS.WHITE[50]),
													fillColorSelected: COLORS.LAKE[50],
													isSelected: isSelected,
													sortKey: isSelected ? activeFields?.length - 1 : i
												},
												type: "Feature"
											},
											{
												...line,
												id: "line",
												properties: {
													isSelected: isSelected,
													lineColor: hexToRGBA(crop?.color, 1, COLORS.WHITE[100]),
													lineColorSelected: COLORS.LAKE[100],
													sortKey: isSelected ? activeFields?.length - 1 : i
												},
												type: "Feature"
											},
											{
												geometry: {
													coordinates: turf.center(polygon)?.geometry?.coordinates,
													type: "Point"
												},
												id: "marker",
												properties: {
													isSelected: isSelected || !selectedFields?.length,
													name: field.name
												},
												type: "Feature"
											}
										];
									}),
							type: "FeatureCollection"
						}}
						id="polygons"
						type="geojson"
					>
						<Layer
							filter={["==", "$type", "Polygon"]}
							id="inside"
							layout={{
								"fill-sort-key": ["get", "sortKey"]
							}}
							paint={{
								"fill-color": [
									"case",
									["get", "isSelected"],
									["get", "fillColorSelected"],
									["get", "fillColor"]
								]
							}}
							source="polygons"
							type="fill"
						/>
						<Layer
							filter={["==", "$type", "LineString"]}
							id="border"
							layout={{ "line-sort-key": ["get", "sortKey"] }}
							paint={{
								"line-color": [
									"case",
									["get", "isSelected"],
									["get", "lineColorSelected"],
									["get", "lineColor"]
								],
								"line-offset": 2.5,
								"line-width": 5
							}}
							source="polygons"
							type="line"
						/>

						<Layer
							filter={["==", "$type", "Point"]}
							id="marker"
							layout={{
								"text-field": ["get", "name"],
								"text-font": ["Rubik Medium"],
								"text-size": 14,
								visibility:
									currentMode === DashboardMode.FIELD_LIST ||
									currentMode === DashboardMode.CROPS_LIST ||
									(currentMode === DashboardMode.NEW_FIELD && creationMode === CreationMode.DRAWING)
										? "visible"
										: "none"
							}}
							minzoom={12}
							paint={{
								"text-color": ["case", ["get", "isSelected"], COLORS.WHITE[100], COLORS.WHITE[50]],
								"text-halo-color": ["case", ["get", "isSelected"], COLORS.BLACK[100], COLORS.BLACK[50]],
								"text-halo-width": 1
							}}
							source="polygons"
							type="symbol"
						/>
					</Source>
					{displayedCenter && displayedArea && (
						<Marker
							anchor="bottom"
							draggable={false}
							latitude={displayedCenter[1]}
							longitude={displayedCenter[0]}
						>
							<MarkerWrapper>
								<MarkerTitle>
									{displayedArea} {t("units.hectare")}
								</MarkerTitle>
							</MarkerWrapper>
						</Marker>
					)}

					<DrawControl
						defaultMode="static"
						displayControlsDefault={false}
						modes={{
							...MapboxDraw.modes,
							direct_select: {
								...MapboxDraw.modes.direct_select,
								clickInactive: function () {
									return;
								},
								clickNoTarget: function () {
									return;
								},
								dragFeature: function () {
									return;
								},
								onMouseMove: function (_state: unknown, e: MapMouseEvent) {
									const isFeature = MapboxDraw.lib.CommonSelectors.isInactiveFeature(e);
									const onVertex = MapboxDraw.lib.CommonSelectors.isOfMetaType(
										MapboxDraw.constants.meta.VERTEX
									)(e);
									const onMidpoint = MapboxDraw.lib.CommonSelectors.isOfMetaType(
										MapboxDraw.constants.meta.MIDPOINT
									)(e);
									if (isFeature || onMidpoint)
										this.updateUIClasses({ mouse: MapboxDraw.constants.cursors.POINTER });
									else if (onVertex)
										this.updateUIClasses({ mouse: MapboxDraw.constants.cursors.MOVE });
									else this.updateUIClasses({ mouse: MapboxDraw.constants.cursors.NONE });
								}
							} as unknown as { clickInactive: () => void; clickNoTarget: () => void } & DrawCustomMode<
								unknown,
								unknown
							>,
							draw_polygon: {
								...MapboxDraw.modes.draw_polygon,
								clickOnVertex: function (state: { polygon: Feature<Polygon> }) {
									try {
										return this.changeMode("direct_select", { featureId: state.polygon.id });
										// eslint-disable-next-line @typescript-eslint/no-unused-vars
									} catch (e) {
										return this.changeMode("draw_polygon");
									}
								}
							} as unknown as DrawCustomMode<unknown, unknown>,
							static: {
								onSetup: () => {
									return {};
								},
								toDisplayFeatures: (_s: unknown, geojson: GeoJSON, display: (geo: GeoJSON) => void) => {
									display(geojson);
								}
							} as unknown as DrawCustomMode<unknown, unknown>
						}}
						onCreate={onUpdate}
						onUpdate={onUpdate}
					/>
				</>
			</FixMapboxMap>
		</Map>
	);
};
export default MapView;
